import AndroidApk from '../apk/CarePlixVitals.apk';

const downloadApk = () => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = AndroidApk;
  a.download = 'Careplix Vitals.apk';
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export default downloadApk;
