import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

import logo from '../../images/footer-careplix-logo.png';

const Header = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box className={classes.footer}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3} style={{ textAlign: 'left' }}>
            <img style={{ width: '80%' }} src={logo} alt="careplix vitals" />
            <p className={classes.copyrighttext}>
              © 2021 CareNow Healthcare Pvt Ltd
            </p>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <ul className={classes.FooterLink}>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
              <li>
                <Link to="/career">Careers</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <ul className={classes.FooterLink}>
              <li>
                <Link to="/corporateapp">Corporate App</Link>
              </li>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://eepurl.com/hylbwf"
                >
                  Become a partner
                </a>
              </li>
              <li>
                <Link to="/vitals">App Download</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <ul className={classes.FooterLink}>
              <li>
                <a href="https://in.linkedin.com/company/carenow-healthcare?trk=public_jobs_topcard-org-name">
                  <LinkedInIcon className={classes.socialIcon} />
                </a>{' '}
                <a href="https://www.instagram.com/careplixvitals/">
                  <InstagramIcon className={classes.socialIcon} />
                </a>{' '}
                <a href="https://www.facebook.com/careplix">
                  <FacebookIcon className={classes.socialIcon} />
                </a>{' '}
                <a href="https://twitter.com/carenowh?lang=en">
                  <TwitterIcon className={classes.socialIcon} />
                </a>
              </li>
              <li>
                <Link to="/terms">Terms of User</Link>
              </li>
              <li>
                <Link to="/policy">Privacy Policy</Link>
              </li>
              <li></li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  footer: {
    background:'linear-gradient(180deg, rgba(209, 209, 209, 0.19) 0%, #FFFFFF 100%);',
    width:'100%',
    padding:'50px 5%',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width:780px)': {
      padding: '20px',
    },
  },
  socialIcon: {
    color: theme.palette.primary.main,
  },
  FooterLink: {
    listStyle: 'none',
    margin: '0px',
    padding: '0px',
    '& li': {
      color: '#3e3e3e',
      lineHeight: '30px',
      fontSize: '14px',
      '& a': {
        color: '#3e3e3e',
        textDecoration: 'none',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  copyrighttext: {
    fontSize: '12px',
    color: '#3e3e3e',
    textAlign: 'left',
  },
}));
