import React, { useState } from 'react';
import { Grid, Box, useMediaQuery, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';

import Drawer from '../../components/Drawer';
import NavList from '../../components/NavList';
import { logout } from '../../store/thunks/auth-thunks';
import { uiActions } from '../../store/reducers/ui-slice';

import Logo from '../../images/careplix-logo.png';

const setNavList = (
  isLoggedIn,
  classes,
  { push = () => {}, logout = () => {}, registerBtnObj = {} },
) => {
  const navList = isLoggedIn
    ? [
        {
          key: '1',
          to: '/user/dashboard',
          linkText: 'Dashboard',
        },
        {
          key: '2',
          to: '/user/my-bookings',
          linkText: 'My Bookings',
        },
        // {
        //   key: '3',
        //   to: '/user/profile',
        //   linkText: 'Profile',
        // },
        {
          key: '4',
          to: '/user/vitals-history',
          linkText: 'Historic Data',
        },
        {
          key: '5',
          to: '/user/report',
          linkText: 'Reports',
        },
        {
          key: '6',
          component: (
            <Button
              variant="text"
              disableElevation
              disableFocusRipple
              disableRipple
              style={{ background: 'transparent' }}
              classes={{ text: classes.LogoutButton }}
              onClick={logout}
            >
              Logout
            </Button>
          ),
        },
      ]
    : [
        {
          key: '5',
          to: '/home',
          linkText: 'Home',
        },
        {
          key: '6',
          to: '/find-doctor',
          linkText: 'Consult Doctors',
        },
        {
          key: '7',
          to: '/vitals',
          linkText: 'Vitals Monitoring',
        },
        {
          key: '8',
          to: '/healthpass',
          linkText: 'HealthPass',
        },
        registerBtnObj,
      ];

  return navList;
};

const Header = () => {
  const classes = useStyles();
  const { push } = useHistory();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const authToken = useSelector((state) => state.auth.authToken);
  const dispatch = useDispatch();

  const breakPointBelow620p = useMediaQuery('(max-width:620px)');
  const breakPointBelow1040p = useMediaQuery('(max-width:1040px)');
  const breakPointBelow1150p = useMediaQuery('(max-width:1150px)');

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleButton = {
    key: 'toggle-menu-button',
    component: (
      <Button
        variant="contained"
        color="primary"
        className={classes.ToggleMenuBtn}
        onClick={setDrawerOpen.bind(null, true)}
      >
        <MenuIcon fontSize="inherit" />
      </Button>
    ),
  };

  const registerBtnObj = {
    key: '9',
    component: (
      <Button
        className={classes.RegisterBtn}
        component={React.forwardRef((props, ref) => (
          <Link to="/user/login" innerRef={ref} {...props} />
        ))}
      >
        Get Started
      </Button>
    ),
  };

  const navList = setNavList(isLoggedIn, classes, {
    push,
    logout: () => {
      dispatch(uiActions.setRedirectToUserLogin());
      dispatch(logout(authToken));
    },
    registerBtnObj,
  });

  let navListInDrawer = [];
  let navListInHeader = [];
  if (!isLoggedIn) {
    navListInHeader = breakPointBelow1150p
      ? breakPointBelow620p
        ? [toggleButton]
        : [registerBtnObj, toggleButton]
      : navList;
    navListInDrawer = breakPointBelow1150p
      ? breakPointBelow620p
        ? navList
        : navList.slice(0, navList.length - 1)
      : [];
  } else {
    navListInHeader = breakPointBelow1040p ? [toggleButton] : navList;
    navListInDrawer = breakPointBelow1040p ? navList : [];
  }

  return (
    <React.Fragment>
      {navListInDrawer.length > 0 ? (
        <Drawer
          open={drawerOpen}
          drawerChildren={<NavList vertical={true} navList={navListInDrawer} />}
          onClose={setDrawerOpen.bind(null, false)}
        />
      ) : null}

      <Box className={classes.Topheader}>
        <Grid container spacing={3}>
          <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
            <Link to="/" className={classes.Logo}>
              <img src={Logo} alt="Logo" className={classes.LogoImage} />
            </Link>
          </Grid>

          <Grid item xs={9}>
            <Box className={classes.RightNav}>
              <NavList vertical={false} navList={navListInHeader} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  Topheader: {
    width: '100%',
    position: 'fixed',
    left: '0px',
    top: '0',
    padding: '0 5%',
    height: '80px',
    background: '#fff',
    zIndex: '999',
    fontSize: theme.typography.pxToRem(16),
  },
  RightNav: {
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width:780px)': {
      paddingRight: '0px',
    },
  },
  ToggleMenuBtn: {
    minWidth: 0,
    width: theme.typography.pxToRem(50),
    height: theme.typography.pxToRem(50),
    fontSize: theme.typography.pxToRem(30),
    padding: 0,
    borderRadius: '50%',
  },
  LogoImage: {
    width: theme.typography.pxToRem(200),
  },
  RegisterBtn: {
    fontSize: 'inherit !important',
    width: 160,
    background:'#2F98F1',
    color:'#fff',
    borderRadius:40,
    height:45,
    '&:hover':{
      background:'#1772bf'
    }
  },
  LogoutButton: {
    textTransform: 'capitalize',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: '#000',
    padding: '0',
    minWidth: '0',

    transition: 'color 0.3s ease-out',

    '&:hover, &:focus': {
      color: theme.palette.info.light,
    },
  },
}));
