const theme = {
  palette: {
    type: 'light',
    primary: {
      main: '#2F98F1',
      contrastText: '#FFFFFF',
      dark: '#1772bf',
      light: '#3F4DC3',
    },
    secondary: {
      main: '#d7dbf3',
      light: '#DFE2F5',
      dark: '#C3C9ED',
      contrastText: '#2F98F1',
    },
    background: {
      paper: '#fcfcff',
      default: '#ffffff',
    },
    success: {
      main: '#09aa1c',
      light: '#3ABB49',
      dark: '#067613',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#f21616',
      light: '#F44444',
      dark: '#A90F0F',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF9800',
      light: '#FFAC33',
      dark: '#B26A00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      main: '#44279c',
      light: '#6952AF',
      dark: '#2F1B6D',
      contrastText: '#FFFFFF',
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: ['"Poppins"', 'sans-serif'].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 9000,
        padding: '12px 40px',
        textTransform: 'none',
        fontSize: 12,

        '@media only screen and (min-width: 780px)': {
          fontSize: 16,
        },
      },
      outlined: {
        padding: '12px 40px',
      },
    },
  },
  props: {
    MuiButton: {
      color: 'primary',
    },
  },
};

export default theme;
