import React, { forwardRef } from 'react';
import { Scrollbars as ScroollBars } from 'react-custom-scrollbars-2';

import ScrollThumb from './ScrollThumb';

const Scrollbars = forwardRef((props, ref) => (
  <ScroollBars
    ref={ref}
    renderThumbHorizontal={ScrollThumb}
    renderThumbVertical={ScrollThumb}
    autoHide
    autoHideTimeout={500}
    autoHideDuration={200}
    {...props}
  />
));

export default Scrollbars;
