import React, { forwardRef } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet as HelmetAsync, HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet';

import Scrollbars from '../components/ScrollBars';

const Page = forwardRef(
  (
    {
      children,
      transitionType = 'fade',
      className = '',
      title = '',
      asyncTitle,
      onScroll,
      onScrollFrame,
      onScrollStart,
      onScrollStop,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles();

    const pageContent = (
      <Box
        className={[transitionType, className, classes.Page].join(' ')}
        {...props}
      >
        <Scrollbars
          ref={ref}
          className={classes.ScrollTrackStyles}
          onScroll={onScroll}
          onScrollFrame={onScrollFrame}
          onScrollStart={onScrollStart}
          onScrollStop={onScrollStop}
        >
          {children}
        </Scrollbars>
      </Box>
    );

    return asyncTitle ? (
      <HelmetProvider>
        <HelmetAsync key={title}>
          <title>{title ? `${title} | ` : ''}Careplix Rewards</title>
        </HelmetAsync>
        {pageContent}
      </HelmetProvider>
    ) : (
      <React.Fragment>
        <Helmet>
          <title>{title ? `${title} | ` : ''}Careplix Rewards</title>
        </Helmet>
        {pageContent}
      </React.Fragment>
    );
  },
);

export default Page;

const useStyles = makeStyles((theme) => ({
  Page: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    paddingRight: theme.spacing(0.1),
    fontSize: theme.typography.pxToRem(16),
  },

  ScrollTrackStyles: {
    '& > div:last-child': {
      zIndex: 999,
    },
  },
}));
