import React,{useState,Component } from 'react';
import { Grid, Box, Button,Typography, List,ListItem } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Marquee from 'react-fast-marquee';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; 
// import { Carousel } from 'react-responsive-carousel';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Link } from 'react-router-dom';
import TextField from "@material-ui/core/TextField";
import Page from '../hoc/Page';
import Footer from '../containers/Footer';
import Select from "react-dropdown-select";
import downloadApk from '../utility/download-apk';
import PlanImage from '../images/plan-image.png';
import cndoc from '../images/cndoc.png';
import appstore from '../images/appstore.png';
import playstore from '../images/playstore.png';
import EnterpriseImg from '../images/enterprice-img.png';
import ServeImg from '../images/serve-img.png';
import slider1 from '../images/covids1.jpg';
import slider2 from '../images/covids2.jpg';
import slider3 from '../images/covids3.jpg';
import Responsiveslider4 from '../images/covids1r.jpg';
import Responsiveslider3 from '../images/covids2r.jpg';
import Responsiveslider5 from '../images/covids3r.jpg';
import pdose from '../images/pdose.png';
import kidVaccine from '../images/kidvaccine.png';
import rtpcr from '../images/rtpcr.png';
import slider4 from '../images/slide5.png';
import Clients1 from '../images/Aditya.jpeg';
import Clients2 from '../images/Philip.png';
import Clients3 from '../images/denton.png';
import Clients4 from '../images/alkem.png';
import TestimonialImage from '../images/cover.png';
import ProfileImg from '../images/testimonial-clients1.jpg';
import ProfileImg2 from '../images/testimonial-clients2.jpg';
import ProfileImg3 from '../images/testimonial-clients3.jpg';
import VideoImg from '../images/video-img.png';
import AppCircle from '../images/App_circle.png';
import SearveImg1 from '../images/insurance.png';
import SearveImg2 from '../images/healthcare.png';
import SearveImg3 from '../images/wellness.png';
import SearveImg4 from '../images/life-science.png';
import SearveImg5 from '../images/mobile.png';
import FeaturedItem from '../images/featured-item.png';
import FeaturedItem2 from '../images/featured-item2.png';
import FeaturedItem3 from '../images/featured-item3.png';
import FeaturedItem4 from '../images/featured-item4.png';
import FeaturedItem5 from '../images/featured-item5.png';
import ListIcon from '../images/List-icon.png';
import DatePicker from "react-date-picker";
import moment from "moment";
import Slide from '@material-ui/core/Slide';
import { apiAxios } from "../utility/ApiAxios";
import axios from "axios";
import { NavigateBeforeSharp } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 767, min: 320 },
    items: 2
  }
};

const Covid19 = () => {
  const classes = useStyles();
  const theme = useTheme();

  const responsive3 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [isOpen, setOpen2] = useState(false);

  const [value, onChange] = useState(new Date());
  const [pincode, setPincode] = useState("");

  const[centerList, setCenterList] = useState([]);
  const [pincodeError, setPincodeError] = useState("");

  const [message, setMessage] = useState("");  

  const [searchState, setSearchState] = useState("");
  //const [stateList, setStateList] = useState([{"label": "West Bengal", "value": "wb"}]);

  function submitForm() {
    let isValid = true;

    console.log("submit form");

    if (pincode.length > 0) {
      setPincodeError(false);
      isValid = true;
    }
    else{
        setPincodeError(true);
        isValid = false;
    } 

    if (isValid) {
      
    
      let date = moment(value).format("DD-MM-YYYY"); 

      axios.get("https://cdn-api.co-vin.in/api/v2/appointment/sessions/public/findByPin?pincode="+pincode+"&date="+date)
    //   .get("​/v2/appointment​/sessions​/public/findByPin?pincode="+pincode+"&date="+date)
      .then((response) => {
        if(response.status == 200){
            console.log("200 - ", response.data.sessions);
            setCenterList(response.data.sessions);
            if(response.data.sessions.length>0){
              setMessage("");
            }
            else{
              setMessage("No Data Found");
            }
        }
        //console.log(centerList);
      })
      .catch((error) => {
        console.log(error);

        // toast.error(error.message, {
        //   position: "bottom-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });

        //localStorage.removeItem("Refresh_Token");
        //localStorage.removeItem("Token");
        //window.location.href = "/";
      });

    }
  }

  return (

    <Page title="Home">
      <Box className={classes.PageContent}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12}>
            <Carousel
              responsive={responsive3}
              showDots={true}
              arrows={false}
              ssr={true}
              customTransition="all .5"
              transitionDuration={500}
              autoPlay={true}
              infinite={true}
              autoPlaySpeed={5000}
              dotListClass="custom-dot-list-style"
            >

              <div>
                <div className={classes.sliderText}>
                <h1>Know About Precaution Dose & Vaccination</h1>
                  <p className={classes.pTextBanner}>Get the list of Booster dose centers and find vaccination slots.</p>
                  
                  <a href="#precautiondose" style={{textDecoration:'none'}}>
                  <Button
                    className={classes.Downloadbtn}
                    style={{ margin: '2% 0' }}
                  >
                    Know More
                  </Button>
                  </a>
                </div>
                <img
                  className={classes.deskSliderImg}
                  src={slider1}
                  alt="careplix vitals"
                />
               <img
                  className={classes.responsiveSliderImg}
                  src={Responsiveslider4}
                  alt="careplix vitals"
                />
              </div>

              <div>
                <div className={classes.sliderText}>
                  <h1>Book Your RT-PCR Test</h1>
                  <p className={classes.pTextBanner}>Hassle free RT-PCR test booking. Call us and we do the rest for you.</p>
                  <a href="#rtpcr" style={{textDecoration:'none'}}>
                  <Button
                    className={classes.Downloadbtn}
                    style={{ margin: '2% 0' }}
                  >
                    Book Now
                  </Button>
                  </a>
                </div>
                <img
                  className={classes.deskSliderImg}
                  src={slider2}
                  alt="careplix vitals"
                />
               <img
                  className={classes.responsiveSliderImg}
                  src={Responsiveslider3}
                  alt="careplix vitals"
                />
              </div>

              <div>
                <div className={classes.sliderText}>
                  <h1>Consult Our In-house Doctors</h1>
                  <p className={classes.pTextBanner}>Consult our physicians and enroll in a 7days RPM plan.</p>
                  
                  <a href="#consultdoctor" style={{textDecoration:'none'}}>
                  <Button
                    className={classes.Downloadbtn}
                    style={{ margin: '2% 0' }}
                  >
                    Consult Now
                  </Button>
                  </a>
                </div>
                <img
                  className={classes.deskSliderImg}
                  src={slider3}
                  alt="careplix vitals"
                />
               <img
                  className={classes.responsiveSliderImg}
                  src={Responsiveslider5}
                  alt="careplix vitals"
                />
              </div>
            </Carousel>
          </Grid>
          <Box className={classes.VitalsMonitor} id="precautiondose">
          <Box className={classes.VitalsMonitorLeft}>
            <h2>Precaution Doses For Senior Citizens</h2>
            <p style={{marginBottom:30,}}>
            Amid rising COVID-19 cases, the booster dose has been rolled out by The Ministry of Health and Welfare.
            <br/>
            From 10th of January 2022, Citizens over the age of  60 years in the country will receive a 'precaution dose' of COVID-19 vaccine.
            </p>
            <Accordion>
                <AccordionItem className={classes.AccordianTab}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What is precautionary Dose of covid vaccine?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p style={{color:'#c3e7fd'}}>
                        Precautionary doses are helpful to enhance the immune protection by maintaining the antibody levels against covid-19.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className={classes.AccordianTab}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How it will save us from recent omicron driven covid surge?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p style={{color:'#c3e7fd'}}>
                        Rise of omicron cases has called for 3rd wave of pandemic. Getting jabbed for the third dose helps to boost our immune system to fight against covid-19 and will keep us safe from severity of the disease, hence reduce the need of hospitalization.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className={classes.AccordianTab}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Who are eligible to get this jab?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p style={{color:'#c3e7fd'}}>
                        All health and frontline workers, and people aged 60 and above with comorbidities are eligible for the Precautionary dose.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className={classes.AccordianTab}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        When one can get this precaution dose?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p style={{color:'#c3e7fd'}}>
                        The precaution dose can only be administered after 9 months, or 39 weeks, from the date of the second dose's administration as recorded in the Cowin system. Eligible candidates will get reminder messages from the Cowin  app and after the dose, it will be documented in the digital vaccination certificate.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className={classes.AccordianTab}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Which vaccination is ideal to get ,covishield or covaxin? 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p style={{color:'#c3e7fd'}}>
                        Both the vaccine are tested against the Omicron varient and found to be effective.However there should not be any mix and match of the vaccines for the precaution dose. For instance, an individual who got a jab with covishield for the first and second dose should get  only covishied administered  for their 3rd dose as well. The same is true for Covaxin also.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className={classes.AccordianTab}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How to get the vaccination?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p style={{color:'#c3e7fd'}}>
                        Citizens don’t need to do any new registration online on Co-win platform. They can use their  existing account or can walk-in to vaccination center with their aadhaar card and their registerd mobile number to get vaccinated. User will only be eligible after 9 months of administration of second dose.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className={classes.AccordianTab}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Where to get the vaccination?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p style={{color:'#c3e7fd'}}>
                        Citizens can check their Co-win account to find nearby vaccination center using their pincode or searching by state and district.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
            
            <Box className={classes.ResponsiveBtn}>
                <Link to="https://selfregistration.cowin.gov.in/">      
                <Button className={classes.GetStartbtn}>Visit Co-Win</Button>
                </Link>
            </Box>
            
          </Box>
          <Box className={classes.VitalsMonitorRight}>
          <img
                      src={pdose}
                      alt="careplix vitals"
                    />
          </Box>
          </Box>
          {/* How it work */}
          <Box className={classes.Howitwork}>
<Box className={classes.HowitworkLeft}>
  <img src={kidVaccine} alt="covid-19 vaccination" style={{width:'100%'}} />
</Box>
<Box className={classes.HowitworkRight}>
  <h3>Find Vaccination Slots</h3>
  <p>Enter pincode and date to find the nearby centers and available slots.</p>
                    <TextField
                      className={classes.input}
                      placeholder="Enter Pincode"
                      type="tel"
                      onChange={(e) => setPincode(e.target.value)}
                    />
                    <DatePicker
                          onChange={onChange}
                          value={value}
                          className={classes.input}
                          minDate={moment().toDate()}
                          style={{height: '52px !important'}}
                        />
 
                        <Button
                          size="large"
                          className={classes.loginbtn}
                          onClick={submitForm}
                        >
                          {" "}
                          Find{" "}
                        </Button> 
                        {pincodeError ? (
                        <p
                          style={{
                            color: "#dc3545",
                            fontSize: ".875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Please provide a valid Pincode.
                        </p>
                      ) : (
                        <></>
                      )}
            {(centerList.length >0)?
            <>
            <Accordion>
            {centerList.map((each) => (
            <Box className={classes.slotBox}>
                
                <AccordionItem className={classes.AccordianTab2}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <h5 className={classes.centerName}>{each.name}</h5>  
                        <p className={classes.centerAddress}>{each.address}</p> 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p style={{color:'#c3e7fd'}}>
                        <h5 className={classes.centerFee}>Vaccine: &nbsp;{each.vaccine}</h5>
                            <h5 className={classes.centerFee}>Cost: &nbsp;{(each.fee>0)? "Rs. "+each.fee : "Free"}</h5>
                            <h5 className={classes.centerFee}>Available: &nbsp;Dose 1: <span style={{backgroundColor: '#192853', padding: '5px', borderRadius: '50px'}}>{each.available_capacity_dose1}</span>  Dose 2: <span style={{backgroundColor: '#192853', padding: '5px', borderRadius: '50px'}}>{each.available_capacity_dose2}</span></h5>
                            <h5 className={classes.centerFee}>Age: {each.min_age_limit} {each.max_age_limit?  " - "+each.max_age_limit : "+"} Yrs</h5>

                            {(each.slots.length >0)?
                            <p style={{textAlign: 'center'}}>
                            {(each.slots).map((slot) => (    
                              <>
                            <p className={classes.centerSlot}>{slot}</p>
                              </>
                            ))}
                            </p>
                            :
                            <span>No Slot Available</span>
                            }
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>            
            </Box>
            ))}
            </Accordion> 
            </>
            :
            <Box style={{marginTop: '5px', textAlign: 'center'}}>
               {message}
            </Box>
            }                   
</Box>
          </Box>
          
          {/* Enterprise section */}
          <Box className={classes.EnterpriseCol} id="rtpcr">
          <Box className={classes.EnterpriseLeft}>
            <h3>Book RT-PCR Test</h3>
          <p>With the outbreak of the third wave, we have seen a huge rush in RT-PCR tests. To help people find appointments quickly and easily, we have joined hands with Diagnostic Lab Partners for hassle free Covid test bookings with faster collection and results.</p>
          <p>Booking through CarePlix is simple. Just call us and we help you to find the nearest and fastest collection from your home or in-center visit.</p>
          <Box className={classes.ResponsiveBtn}>
          <Button href="tel:03341816533" className={classes.GetStartbtn} style={{width:'auto',fontSize:'1.5rem', padding:'12px'}}>Call us at 033-41816533</Button>
          </Box>
          </Box>
            <Box className={classes.EnterpriseRight}>
            <img src={rtpcr} alt="covid rt-pcr test" />
            </Box>
          </Box>
          {/* Why Careplix */}
          <Box className={classes.WhyCareplix} id="consultdoctor">
               <Box className={classes.CertificatesLeft}>
               <img src={cndoc} alt="careplix vitals" style={{width:'100%'}} />
               </Box>
               <Box className={classes.CertificatesRight}>
                 <Typography variant='h3' className={classes.CertificatesTitle}>Consult With Our In-house Doctors</Typography>
                 <Typography className={classes.CertificatesContent}>We have a team of expert in-house doctors. Call us at 033-41816533, our CarePlix Happiness team will connect you with one of our physician. We confirm an appointment and book the slot, once patient pays the required fees. The CarePlix Happiness team will send you a link to join the appointment through our secured HIPPA compliant teleconsult platform prior to the consultation time.</Typography>
                 <Typography className={classes.CertificatesContent}>We are also offering Remote Patient Monitoring at a very basic lavel. Any patient can enroll to our RPM program for 3 days, 7 days program. Once a patient is enrolled to the program, the associated provider captures medical and other health data from patients through telephonic calls for daily assessment and provides recommendations and instructions. </Typography>
                  <Box>
                  <Accordion>
                  <Box className={classes.slotBox}>
                      <AccordionItem className={classes.AccordianTab2}>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              <h4 className={classes.centerName}>Tele-Consult</h4>  
                              <p className={classes.centerAddress}>Rs. 400/- for 15 mins.</p> 
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                          </AccordionItemPanel>
                      </AccordionItem>            
                  </Box>
                  <Box className={classes.slotBox}>
                      <AccordionItem className={classes.AccordianTab2}>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              <h4 className={classes.centerName}>RPM 3 days</h4>  
                              <p className={classes.centerAddress}>Rs. 999/-</p> 
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p className={classes.centerAddress}>Provider calls: 2-3 times per day</p> 
                              <p className={classes.centerAddress}>Vitals Monitoring Devices: Patient should have the necessary vitals monitoring devices like BP monitor, Oximeter, Glucose monitor (optional) </p>
                              <p className={classes.centerAddress}>Daily Reports: Yes</p>  
                          </AccordionItemPanel>
                      </AccordionItem>            
                  </Box>
                  <Box className={classes.slotBox}>
                      <AccordionItem className={classes.AccordianTab2}>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              <h4 className={classes.centerName}>RPM 7 days</h4>  
                              <p className={classes.centerAddress}>Rs. 1999/-</p> 
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p className={classes.centerAddress}>Provider calls: 2-3 times per day</p> 
                              <p className={classes.centerAddress}>Vitals Monitoring Devices: Patient should have the necessary vitals monitoring devices like BP monitor, Oximeter, Glucose monitor (optional) </p>
                              <p className={classes.centerAddress}>Daily Reports: Yes</p>  
                          </AccordionItemPanel>
                      </AccordionItem>            
                  </Box>
                  <Box className={classes.slotBox}>
                      <AccordionItem className={classes.AccordianTab2}>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                              <h4 className={classes.centerName}>Pre-order our IoMT devices</h4>  
                              <p className={classes.centerAddress}>Call to know more about our IoMT devices.</p> 
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                          </AccordionItemPanel>
                      </AccordionItem>            
                  </Box>
                  </Accordion>
                  </Box> 
                  <Box className={classes.ResponsiveBtn} style={{width:'100%'}}>
                    <Button href="tel:03341816533" className={classes.GetStartbtn} style={{width:'100%',fontSize:'1.5rem', padding:'15px'}}><b>Book Now at 033-41816533</b></Button>
                  </Box>
               </Box>
          </Box>

      <Box style={{width:'100%', textAlign:'center'}}>
        <img src={TestimonialImage} alt="careplix app" style={{width:'100%'}} />
              <h1>Download The App Now!</h1>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  downloadApk();
                }}
              >
                <img
                  style={{ width: '25%' }}
                  src={playstore}
                  alt="careplix vitals"
                />
              </a>
              <a href="https://apps.apple.com/no/app/careplix-vitals/id1547993761">
                <img
                  style={{ width: '25%' }}
                  src={appstore}
                  alt="careplix vitals"
                />
              </a>
      </Box>    

      <Box className={classes.OurPartner}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12}>
            <Box className={classes.partnercontent}>
              <h1 className={classes.HeadingText}>
              Trusted By
              </h1>
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={3} style={{ textAlign: 'center' }}>
            <img
              className={classes.carepliximg}
              src={Clients2}
              alt="careplix vitals"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} style={{ textAlign: 'center' }}>
            <img
              className={classes.carepliximg}
              src={Clients4}
              alt="careplix vitals"
              style={{width:'40%'}}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} style={{ textAlign: 'center' }}>
            <img
              className={classes.carepliximg}
              src={Clients1}
              alt="careplix vitals"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} style={{ textAlign: 'center' }}>
            <img
              className={classes.carepliximg}
              src={Clients3}
              alt="careplix vitals"
            />
          </Grid>
        </Grid>
      </Box>
    
      
      

      <Box className={classes.clients}>
        <Box className={classes.PageContentApp}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
              <h1 style={{ color: theme.palette.primary.main }}>Featured In</h1>
            </Grid>
            <Grid item xs={12} md={12} style={{ marginBottom: '0rem' }}>
            <Carousel 
            partialVisible={true}
            swipeable={true}
            draggable={true}
            ssr={true}
            responsive={responsive}
            infinite={true}
            slidesToSlide={1}
  autoPlay={true}
  autoPlaySpeed={1500}
  keyBoardControl={true}
  customTransition="all 1"
  transitionDuration={1000}
            >
  <div><img
                  
                  src={FeaturedItem}
                  alt="client logo"
                 
                /></div>
  <div><img
                  
                  src={FeaturedItem2}
                  alt="client logo"
                  
                /></div>
  <div><img
                  
                  src={FeaturedItem3}
                  alt="client logo"
                  
                /></div>
  <div><img
                  
                  src={FeaturedItem4}
                  alt="client logo"
                  
                /></div>
  <div><img
                  
                  src={FeaturedItem5}
                  alt="client logo"
                  
                /></div>
  <div><img
                  
                  src={FeaturedItem}
                  alt="client logo"
                  
                /></div>
  <div><img
                  
                  src={FeaturedItem2}
                  alt="client logo"
                  
                /></div>
                <div><img
                  
                  src={FeaturedItem3}
                  alt="client logo"
                  
                /></div>
                <div><img
                  
                  src={FeaturedItem4}
                  alt="client logo"
                  
                /></div>
                <div><img
                
                  src={FeaturedItem5}
                  alt="client logo"
                  
                /></div>
            </Carousel>    
            </Grid>
          </Grid>
        </Box>
      </Box>
      </Grid>
      </Box>
          

      <Footer />
    </Page>
  );
};

export default Covid19;

const useStyles = makeStyles((theme) => ({
  PageContent: {
    width: '100%',
    margin: '80px auto 0 auto',
    '& .react-multi-carousel-dot-list':{
      bottom:40,
    },
    '& p': {
      '@media (max-width:780px)': {
        fontSize: '14px',
      },
    },
    '& h2': {
      fontSize: '50px',
      fontWeight: '700',
      '@media (max-width:780px)': {
        fontSize: '30px',
      },
    },
  },
  centerName: {
    marginTop: '8px',
    marginBottom: '5px'
  },
  centerAddress: {
    color: '#e1e2e3 !important',
    fontSize: '0.8rem'
  },
  centerFee: {
    marginTop: '4px',
    color: '#ffffff'
  },
  Modal:{
    '& iframe':{
      width:'100%',
      minWidth:'500px',
      '@media (max-width:780px)': {
        minWidth:'100%',
      },
    }
  },
  contentcol:{
    padding:'0 15%',
    marginBottom:30,
    '@media (max-width:780px)': {
      padding:'0 0%',
      marginBottom:45,
    }
  },
  pTextBanner:{
    fontSize: '2rem !important',
    color: '#ededed !important',
    '@media (max-width:780px)': {
      fontSize: '1.5rem !important',
      color: '#ededed !important'
    },
  },
  centerSlot: {
    margin: '5px 5px',
    backgroundColor: '#ffffff',
    color: '#000 !important',
    padding: '5px',
    textAlign: 'center',
    fontSize: '0.8rem'
  },
  loginbtn: {
    background: "#0597dd",
    padding: "0 40px",
    width: "180px",
    height: "50px",
    borderRadius: "10px",
    textTransform: "capitalize",
    color: "#fff",
    "&:hover": {
      background: "#250247",
    },
  },
  EnterpriseCol:{
    width:'100%',
    padding:'0 5% 70px',
    display:'flex',
    '@media (max-width:780px)': {
      flexDirection:'column'
    },
  },
  input: {
    border: "none !important",
    borderRadius: "10px !important",
    height: "50px",
    margin: '8px',
    backgroundColor: '#ededed',
    "& .MuiInput-input:focus": {
        border: "1px #7087A7 solid",
        boxShadow: "2px 2px 10px 1px rgba(0,0,0,0.3)",
      },
      "& .MuiInputBase-input": {
        height: "50px",
        borderRadius: "10px",
        border: "1px #D5D5D5 solid",
        backgroundColor: "#F9F9F9",
        padding: "0 15px",
      },
      "& .MuiInput-underline:before": {
        display: "none",
      },
      "& .react-date-picker__wrapper": {
        borderRadius: 10,
        border: "1px #D5D5D5 solid",
        backgroundColor: "#F9F9F9",
        padding: "5px 10px",
      },
      "& .react-date-picker__inputGroup__input:focus": {
        border: 0,
        boxShadow: "none",
      },
      "& .MuiInput-underline:after": {
        display: "none",
      },
  },
  EnterpriseLeft:{
    width:'50%',
    paddingRight:'5%',
    '@media (max-width:780px)': {
      order:2,
      width:'100%',
      paddingRight:'0'
    },
'& p':{
  color:'#686868',
  textAlign:'justify'
},
    '& h3':{
      fontWeight:'700',
      fontSize:30,
      color:'#2F98F1',
      '@media (max-width:780px)': {
        textAlign:'center',
        fontSize:24,
      }
    }
  },
  WhyCareplix:{
    width:'100%',
    padding:'70px 5%',
    display:'flex',
    '@media (max-width:780px)': {
      flexDirection:'column'
    }
  },
  CertificatesLeft:{
    width:'50%',
    '& img':{
      '@media (max-width:780px)': {
        width:'100%'
      } 
    },
    '@media (max-width:780px)': {
      width:'100%'
    }
  },
  CertificatesRight:{
    width:'50%',
    '@media (max-width:780px)': {
      width:'100%'
    }
  },
  slotBox: {
    width: '33%',
    float: 'left',
    padding: '0.5%',
    '@media (max-width:780px)': {
      width:'100%'
    }
  },
  CertificatesTitle:{
    fontSize:30,
    color:'#2F98F1',
    fontWeight:'700',
    marginTop:0,
    marginBottom:30,
    '@media (max-width:780px)': {
      textAlign:'center',
      marginTop:30,
    }
  },
  AccordianTab:{
    background: 'linear-gradient(52deg, #3298f1, #223288)',
    padding: '6px 15px',
    color: '#fff',
    borderRadius: '10px',
    marginTop: '10px',
    cursor: 'pointer',
  },
  AccordianTab2:{
    background: 'linear-gradient(52deg, #3298f1, #223288)',
    padding: '6px 15px',
    color: '#fff',
    borderRadius: '10px',
    marginTop: '10px',
    cursor: 'pointer',
    minHeight: '140px',
    '@media (max-width:780px)': {
      minHeight: 'auto'
    }
  },
  select:{
    width:'100%',
    border:'none !important',
    borderRadius:'10px !important',
    border:'1px #D5D5D5 solid',
          backgroundColor:'#F9F9F9',
    height:'50px',
    fontSize:'18px !important',
    paddingLeft:'15px !important',
    paddingRight:'15px !important'
  },
  CertificatesContent:{
    color:'#686868',
  textAlign:'justify',
  fontSize:16,
  marginBottom:20,

  },
  EnterpriseRight:{
    width:'50%',
    textAlign:'center',
    marginTop:50,
    '@media (max-width:780px)': {
      order:1,
      width:'100%'
    },
    '& img':{
      width:'100%'
    }
  },
  PriceTitle:{
    fontSize:24,
    color:'#2F98F1',
    fontWeight:'700',
    textAlign:'center',
    marginBottom:30,
    marginTop:20,
    '@media (max-width:780px)': {
      fontSize:20,
      marginTop:15,
    }
  },
  Price:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  PriceHeading:{
    fontSize:34,
    fontWeight:'700',
    color:'#2F98F1',
    textAlign:'center',
    marginBottom:30,
    '@media (max-width:780px)': {
      fontSize:24,
    }
  },
  PriceContent:{
    fontSize:16,
    color:'#686868',
    padding:'0 15%',
    textAlign:'center',
    marginBottom:40,
    '@media (max-width:780px)': {
      padding:'0 5%',
    }
  },
  Amount:{
    fontSize:40,
    color:'#686868',
    fontWeight:'600',
    marginBottom:15,
    textAlign:'center',
    marginRight:5,
  },
  OfferList:{
    '& img':{
      marginRight:10,
    }
  },
  Discount:{
    fontWeight:'600',
    fontSize:16,
    color:'#2F98F1',
    '& span':{
      color:'#686868'
    }
  },
  Pricing:{
    width:'100%',
    padding:'30px 5% 70px',
    '@media (max-width:780px)': {
      padding:'0 5% 50px',
    }
  },
  PricingCol:{
    display:'flex',
    justifyContent:'space-between',
    '@media (max-width:780px)': {
      flexDirection:'column',
    },
  },
  PricingBox:{
    width:'30%',
    border:'1px #2F98F1 solid',
    padding:20,
    borderRadius:10,
    boxShadow:'5px 5px 30px rgba(0, 0, 0, 0.08)',
    '& .MuiListItem-gutters':{
      '@media (max-width:780px)': {
        paddingLeft:0,
        paddingRight:0,
      }
    },
    '@media (max-width:780px)': {
      width:'100%',
      marginBottom:30,
    }
  },
  Howitwork:{
    width:'100%',
    background:'linear-gradient(180deg, #ffffff 0%, rgba(234, 234, 234, 0) 100%);',
    padding:'70px 5%',
    display:'flex',
    '@media (max-width:780px)': {
      flexDirection:'column',
      padding:'70px 5% 0',
      textAlign: 'center'
    },
  },
  VideoCol:{
    padding:0,
  },
  HowitworkLeft:{
    width:'50%',
    position:'relative',
    '& img':{
      '@media (max-width:780px)': {
        width:'100%'
      }
    },
    '@media (max-width:780px)': {
      width:'100%',
      marginBottom:30,
    }
  },
  HowitworkRight:{
    width:'50%',
    '@media (max-width:780px)': {
      width:'100%'
    },
    '& p':{
      color:'#686868'
    },
    '& h3':{
      fontSize:30,
      color:'#272727',
      fontWeight:'700',
      marginTop:0,
      '@media (max-width:780px)': {
        fontSize:24,
        textAlign:'center',
      }
    }
  },
  Btncol:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    marginBottom:10,
  },
  VitalsMonitor:{
    width:'100%',
    padding:'30px 5%',
    display:'flex',
    '@media (max-width:780px)': {
      flexDirection:'column'
    },

  },
  VitalsMonitorRight:{
    width:'50%',
    '@media (max-width:780px)': {
      order:1,
      width:'100%',
    },
    '& img':{
      width:'100%'
    }
  },
  GetStartbtn:{
    width:178,
    height:45,
    background:'#2F98F1',
    borderRadius:20,
    fontSize:16,
    color:'#fff',
    marginTop:30,
    '&:hover':{
      background:'#1772bf'
    }
  },
  ResponsiveBtn:{
    '@media (max-width:780px)': {
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      marginBottom:20,
    }
  },
  VitalsMonitorLeft:{
    width:'50%',
    paddingRight:50,
    '@media (max-width:780px)': {
      order:2,
      width:'100%',
      paddingRight:0,
    },
    
    '& p':{
      fontSize:14,
      color:'#686868',
      textAlign:'justify'
    },
    '& h2':{
      fontSize:30,
      fontWeight:'700',
      color:'#2F98F1',
      '@media (max-width:780px)': {
        textAlign:'center',
        fontSize:24,
      }
    }
  },
  ProfileImg:{
    width:130,
    height:130,
    borderRadius:'50%',
    background:'#fff',
    padding:5,
    marginRight:30,
    display:'flex',
    overflow:'hidden',
    '& img':{
      width:'100%',
      borderRadius:'50%'
    }
  },
  TestimonialList:{
    display:'flex',
    '@media (max-width:780px)': {
flexDirection:'column',
justifyContent:'center',
alignItems:'center'
    },
  },
  TestimonialContent:{
    width:'70%',
    height:240,
    '@media (max-width:780px)': {
      width:'100%'
    },
    '& p':{
      fontSize:14,
      color:'#fff'
    },
    '& h4':{
      fontSize:18,
      color:'#fff',
      '& span':{
        fontWeight:'300',
        fontSize:14
      }
    }
  },
  IndustriesCol:{
    width:'100%',
    backgroundImage: `url(${ServeImg})`,
    padding:'70px 10%',
    height:500,
    backgroundRepeat  : 'no-repeat',
    backgroundSize: 'cover',
    textAlign:'center',
    '@media (max-width:780px)': {
      padding:'70px 5%',
      height:'auto',
    },
    '& h3':{
      fontSize:30,
      fontWeight:'700',
      color:'#272727',
      marginTop:0,
    },
    '& ul':{
      listStyle:'none',
      margin:0,
      padding:0,
      display:'flex',
      flexWrap:'wrap',
      justifyContent:'space-between',
      '& li':{
        width:'20%',
        textAlign:'center',
        '@media (max-width:780px)': {
          width:'50%',
          marginBottom:15,
        },
        '& p':{
          marginTop:5,
        }
      }
    }
  },
  Testimonial:{
    width:'100%',
    backgroundImage: `url(${TestimonialImage})`,
    padding:'70px 25%',
    height:500,
    backgroundRepeat  : 'no-repeat',
    backgroundSize: 'cover',
    '@media (max-width:780px)': {
      padding:'50px 5%',
      width:'100%',
      height: '160px'
    },
    '& .react-multi-carousel-dot-list':{
      left:'initial',
      '@media (max-width:780px)': {
        right:'37%',
      }
    },
    '& h2':{
      fontSize:30,
      fontWeight:'700',
      color:'#fff',
      textAlign:'center',
      marginBottom:60,
    }
  },
  TopPlanRight:{
    width:'55%',
    '@media (max-width:780px)': {
      order:1,
      paddingRight:0,
      width:'100%'
    },
  },
  TopPlanLeft:{
    width:'45%',
    paddingRight:40,
    '& img':{
      '@media (max-width:780px)': {
        width:'100%'
      }
    },
    '@media (max-width:780px)': {
      order:2,
      paddingRight:0,
      width:'100%'
    },
    '& p':{
      fontSize:14,
      color:'#686868',
      '@media (max-width:780px)': {
        width:'100%',
        padding:'0 5%'
      }
    }
  },
  PlanTitle:{
    fontSize:30,
    fontWeight:'700',
    color:'#2F98F1',
    marginLeft:20,
    marginBottom:20,
    display:'flex',
    '@media (max-width:780px)': {
      fontSize:24,
      textAlign:'center',
      justifyContent:'center',
      marginBottom:40,
    }
  },
  PlanList:{
    listStyle:'none',
    margin:0,
    padding:0,
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap',
    '@media (max-width:780px)': {
      flexWrap:'nowrap',
      flex:1,
      overflowX:'auto'
    },
    '& li':{
      borderRadius:10,
      border:'1px solid #2F98F1',
      boxShadow:'5px 5px 30px rgba(0, 0, 0, 0.08)',
      padding:20,
      marginLeft:20,
      marginBottom:20,
      width:'47%',
      '@media (max-width:780px)': {
        flex:'0 0 55% !important',
        display:'flex',
        flexDirection:'column'
      },
      '& h4':{
        fontWeight:'700',
        color:'#686868',
        fontSize:30,
        margin:0,
      },
      '& h3':{
        fontSize:22,
        fontWeight:'700',
        color:'#2F98F1',
        marginTop:0,
        marginBottom:10,
      },
      '& p':{
        fontSize:12,
        color:'#686868',
        marginBottom:0,
      }
    }
  },
  PlanHeading:{
    fontSize:30,
    color:'#2F98F1',
    fontWeight:'700'
  },
  TopPlan:{
    width:'100%',
    padding:'30px 5% 50px',
    flexDirection:'row',
    display:'flex',
    '@media (max-width:780px)': {
      flexDirection:'column',
      padding:'0 0 50px',
    },
  },
  OurPartner:{
    background:'linear-gradient(180deg, #fff 0%, #FFFFFF 100%);',
    width:'100%',
    padding:'0 5%',
    marginBottom:70,
    '@media (max-width:780px)': {
      paddingTop:30,
    }
  },
  Downloadbtn:{
    color:'#2F98F1',
    borderRadius:40,
    fontSize:16,
    width:200,
    height:50,
    background:'#fff',
    '@media (max-width:780px)': {
      marginTop:'25px !important',
    },
    '&:hover':{
      background:'#2F98F1',
      color:'#fff'
    }
  },
  HeadingText:{
    fontSize:30,
    color:'#272727',
    '@media (max-width:780px)': {
      marginBottom:30,
    }
  },
  ordercol1: {
    '@media (max-width:780px)': {
      order: '2',
    },
  },
  ordercol3: {
    '@media (max-width:780px)': {
      order: '3',
    },
  },
  ordercol4: {
    '@media (max-width:780px)': {
      order: '4',
    },
  },
  ordercol5: {
    '@media (max-width:780px)': {
      order: '6',
    },
  },
  ordercol6: {
    '@media (max-width:780px)': {
      order: '5',
    },
  },
  partnercol: {
    textAlign: 'center',
    padding: '8%',
    '& h1': {
      '@media (max-width:780px)': {
        fontSize: '24px',
      },
    },
    '& p': {
      width: '80%',
      paddingLeft: '20%',
      color: '#efefef',
      '@media (max-width:780px)': {
        paddingLeft: '0%',
        width: '100%',
        fontSize: '14px',
      },
    },
    '@media (max-width:780px)': {
      padding: '4%',
    },
  },
  carepliximg:{
     width: '50%',
    '@media (max-width:780px)': {
      width:'85%',
      marginBottom:20,
    },
  },
  partnercontent: {
    textAlign: 'center',
    padding: '2%',
    '& p': {
      width: '80%',
      paddingLeft: '20%',
      '@media (max-width:780px)': {
        width: '100%',
        paddingLeft: '0%',
      },
    },
  },
  PageContent2: {
    width: '90%',
    margin: '90px auto 50px auto',
    '& p': {
      '@media (max-width:780px)': {
        fontSize: '14px',
      },
    },
    '@media (max-width:780px)': {
      margin: '30px auto 30px auto',
      textAlign: 'center',
    },
    '& h1': {
      '@media (max-width:780px)': {
        fontSize: '24px',
      },
    },
    '& h2': {
      fontSize: '50px',
      fontWeight: '700',
      '@media (max-width:780px)': {
        fontSize: '30px',
      },
    },
  },
  healthcontent: {
    textAlign: 'left',
    padding: '2% 8%',
    '& p': {
      width: '80%',
      '@media (max-width:780px)': {
        width: '100%',
      },
    },
    '& h1': {
      '@media (max-width:780px)': {
        fontSize: '24px',
      },
    },
    '@media (max-width:780px)': {
      padding: '2% 4% 0',
      textAlign: 'center',
    },
  },
  keepcontent: {
    textAlign: 'left',
    padding: '8% 0 8% 18%',
    '& p': {
      width: '80%',
      '@media (max-width:780px)': {
        width: '100%',
        fontSize: '14px',
      },
    },
    '& h1': {
      '@media (max-width:780px)': {
        fontSize: '24px',
      },
    },
    '@media (max-width:780px)': {
      textAlign: 'center',
      padding: '4%',
    },
  },
  caredoc: {
    paddingTop: '6%',
    textAlign: 'right',
    '& p': {
      paddingLeft: '20%',
      '@media (max-width:780px)': {
        paddingLeft: '0%',
      },
    },
    '& h1': {
      '@media (max-width:780px)': {
        fontSize: '24px',
      },
    },
    '@media (max-width:780px)': {
      textAlign: 'center',
      paddingTop: '0%',
    },
  },
  vitalscontent: {
    paddingTop: '12%',

    '& p': {
      width: '80%',
      '@media (max-width:780px)': {
        width: '100%',
      },
    },
    '& h1': {
      '@media (max-width:780px)': {
        fontSize: '24px',
      },
    },

    '@media (max-width:780px)': {
      paddingTop: '4%',
      textAlign: 'center',
    },
  },
  offerImg: {
    marginTop: '5rem',
    '@media (max-width:780px)': {
      marginTop: '0rem',
    },
  },
  desk_pluseicon: {
    '@media (max-width:780px)': { display: 'none' },
  },
  res_pluseicon: {
    display: 'none',
    '@media (max-width:780px)': { display: 'block' },
  },
  Offercontent: {
    marginTop: '5rem',
    '@media (max-width:780px)': {
      textAlign: 'center',
      marginTop: '1rem',
    },
    '& h1': {
      '@media (max-width:780px)': {
        fontSize: '24px',
      },
    },
  },
  stepcontent: {
    width: '60%',
    margin: '0 20%',
    '@media (max-width:780px)': {
      margin: '0 5% 25px',
      width: '90%',
    },
  },
  responsiveSliderImg: {
    display: 'none',
    width: '100%',
    '@media (max-width:780px)': {
      display: 'block',
    },
  },
  deskSliderImg: {
    width: '100%',
    '@media (max-width:780px)': {
      display: 'none',
    },
  },
  sliderText: {
    zIndex: '2',
    position: 'absolute',
    padding: '5%',
    '@media (max-width:991px)': {
      padding: '2% 4%',
    },
    '@media (max-width:780px)': {
      padding: '2% 4%',
      textAlign: 'center',
      width:'100%',
      top:0,
      bottom:0,
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center'
    },
    '& h1': {
      color:'#fff',
      fontSize: '3rem',
      marginBottom: '2%',
      '@media (max-width:991px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width:780px)': {
        fontSize: '1.5rem',
      },
    },
    '& p': {
      color: '#3e3e3e',
      fontSize: '1rem',
      width: '55%',
      '@media (max-width:991px)': {
        width: '45%',
        fontSize: '14px',
      },
      '@media (max-width:780px)': {
        fontSize: '14px',
        width: '100%',
      },
    },
  },
  sliderTextOxySat: {
    width: '60%',
    zIndex: '2',
    position: 'absolute',
    padding: '8% 5%',
    '@media (max-width:991px)': {
      padding: '2% 4%',
      width: '50%',
    },
    '@media (max-width:780px)': {
      padding: '2% 4%',
      textAlign: 'center',
      width: '100%',
    },
    '& h1': {
      color: theme.palette.primary.main,
      fontSize: '2.5rem',
      marginBottom: '2%',
      '@media (max-width:991px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width:780px)': {
        fontSize: '1.5rem',
      },
    },
    '& p': {
      color: '#3e3e3e',
      fontSize: '1rem',
      width: '100%',
      '@media (max-width:991px)': {
        fontSize:14,
      },
      '@media (max-width:780px)': {
        fontSize: '10px',
        width: '100%',
      },
    },
  },
  linkStyles: {
    fontSize: '20px',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    transition: 'color 0.3s ease-out',

    '&:hover, &:focus': {
      color: theme.palette.info.light,
    },
  },
  clientlogo: {
    width: '6%',
    padding: '0px 20px',
  },
  PageContentApp: {
    width: '100%',
    margin: '30px auto 30px auto',
  },
  Border: {
    borderBottom: '1px solid #d0d0d0',
    paddingTop: '2rem !important',
    marginBottom: '1rem !important',
  },
  mainText: {
    textAlign: 'center',
    '& h1': {
      color: theme.palette.primary.main,
      '@media (max-width:780px)': {
        fontSize: '24px',
      },
    },
  },
  SliderImg: {
    width: '100%',
    borderRadius: '20px',
  },
  homecard: {
    width: '35%',
    borderRadius: '20px',
    '&:hover': {
      boxShadow: '0px 12px 12px 4px #00000030',
    },
  },
  vitalsapp: {
    width: '100%',
    background: 'linear-gradient(134deg, #1021b4 0%, #01094a 100%);',
    textAlign: 'center',
    padding: '30px 0',
    overflow: 'hidden',
    margin: '0',

    '& h4': {
      color: '#fff',
      fontSize: '28px',
      fontWeight: '700',
      marginBottom: '0px',
      textAlign: 'center',
      '@media (max-width:780px)': {
        fontSize: '20px',
      },
    },
  },
  clients: {
    width: '100%',
    background: '#ffffff',
    textAlign: 'center',
    padding: '10px 5%',
    marginTop: '20px',
    overflow: 'hidden',
    '@media (max-width:780px)': {
      marginTop: '0px',
    },
  },
}));

// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 6,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 4,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 3,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 2,
//   },
// };
// const responsive2 = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 4,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

// CTAButton: {
//   width: '171px',
//   fontSize: 'inherit',
//   background: theme.palette.primary.main,
//   borderRadius: '40px',
//   color: '#fff',
//   padding: '2% 0',
//   margin: '2% 0',
//   '&:hover': {
//     background: '#0b1888',
//   },
//   '@media (max-width:780px)': {
//     fontSize: '12px',
//   },
// },
// listStyle: {
//   listStyle: 'none',
//   paddingLeft: '0',
//   '& li': {
//     padding: '15px 0',
//     display: 'flex',
//     '& svg': {
//       marginRight: '10px',
//     },
//   },
// },
// pack: {
//   height: '250px',
//   width: '90%',
//   border: '1px solid #e6e6e6',
//   margin: '30px',
//   borderRadius: '20px',
// },
// viewBtn: {
//   padding: '5px 10px',
//   background: '#fff',
//   color: theme.palette.primary.main,
//   margin: '10px 35px',
//   borderRadius: '10px',
// },
// docImg: {
//   textAlign: 'center',
//   '& h4': {
//     margin: '5px',
//     color: '#fff',
//   },
//   '& p': {
//     margin: '1px',
//     color: '#aeaeae',
//   },
//   margin: '10% 5%',
//   padding: '5%',
//   border: '2px solid #f1f1f1',
//   borderRadius: '20px',
//   background: 'linear-gradient(3deg, #1021b4, #617cff)',
//   '&:hover': {
//     boxShadow: '0px 12px 12px 4px #00000030',
//   },
// },
// docImgCard: {
//   width: '150px',
//   height: '150px',
//   borderRadius: '100px',
//   border: '5px solid #fff',
// },
// LearnMoreBtn: {
//   width: '171px',
//   fontSize: 'inherit',
//   background: theme.palette.primary.main,
//   color: '#fff',
//   borderRadius: '10px',
//   '&:hover': {
//     background: '#18216d',
//   },
// },
// CreditBox: {
//   width: '269px',
//   height: '189px',
//   padding: '0 20px 20px',
//   borderRadius: '30px',
//   boxShadow: '0px 0px 12px 8px rgba(0, 0, 0, 0.12)',
//   position: 'absolute',
//   left: '20px',
//   bottom: '30px',
//   background: '#fff',
//   zIndex: '99',
//   '@media (max-width:780px)': {
//     width: '175px',
//     height: '135px',
//     borderRadius: '20px',
//   },

//   '& h3': {
//     fontSize: '30px',
//     marginBottom: '0px',
//     marginTop: '20px',
//     '@media (max-width:780px)': {
//       fontSize: '20px',
//     },
//   },

//   '& h4': {
//     fontSize: '42px',
//     margin: '0px',
//     '@media (max-width:780px)': {
//       fontSize: '30px',
//     },
//   },

//   '& img': {
//     width: '70px !important',
//     height: '70px',
//     marginTop: '-15px',
//     '@media (max-width:780px)': {
//       width: '50px !important',
//       height: '50px',
//       marginTop: '-10px',
//     },
//   },
// },
// CreditForm: {
//   padding: '140px 40px 0',
//   '@media (max-width:780px)': {
//     padding: '0',
//   },
// },
// CreditFormHeading: {
//   fontSize: '32px',
//   fontWeight: '700',
//   '@media (max-width:780px)': {
//     fontSize: '24px',
//   },
// },
// HeaderImage: {
//   width: '100%',
//   borderRadius: '20px',
//   overflow: 'hidden',
//   marginTop: '30px',
//   position: 'relative',
//   '& img': {
//     width: '100%',
//   },
// },
// cardWidth: {
//   maxWidth: '100%',
//   boxShadow: 'none',
// },
// overlay: {
//   gradientColor: 'none !important',
// },
// media: {
//   height: '140',
// },
